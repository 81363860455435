<template>
  <div class="login-wrap">
    <div class="free-header">
      <div class="div">
        <i> <img alt="" src="../assets/img/logo.jpg" /></i>
        <ul class="ul">
          <!-- <li data-v-68964816="">首页</li>
          <li class="">关于我们</li>
          <li class="">防劫持教程</li>
          <li class="">在线客服</li>
          <li class="">联盟合作</li>
          <li class="">手机客户端</li> -->
        </ul>
      </div>
    </div>
    <div class="ms-login">
      <div class="box">
        <div class="ms-title">注册</div>
        <el-form
          :model="param"
          :rules="rules"
          ref="login"
          label-width="0px"
          class="ms-content"
        >
          <el-form-item prop="username">
            <el-input v-model="param.username" placeholder="请输入用户名">
              <template #prepend>
                <el-button icon="el-icon-user"></el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              placeholder="请输入密码"
              v-model="param.password"
              
            >
              <template #prepend>
                <el-button icon="el-icon-lock"></el-button>
              </template>
            </el-input>
          </el-form-item>
           <el-form-item prop="confirm_pwd">
            <el-input
              type="password"
              placeholder="请再次输入密码"
              v-model="param.confirm_pwd"
             
            >
              <template #prepend>
                <el-button icon="el-icon-lock"></el-button>
              </template>
            </el-input>
          </el-form-item>

           <el-form-item prop="parent_code">
            <el-input
              
              placeholder="请输入邀请码"
              v-model="param.parent_code"
              @keyup.enter="submitForm()"
            >
              <template #prepend>
                <el-button icon="el-icon-s-promotion"></el-button>
              </template>
            </el-input>
          </el-form-item>
          <div class="login-btn">
            <el-button type="danger" @click="submitForm()">注册</el-button>
          </div>
        </el-form>
      </div>
      <div class="tabs">
        <p>
          已有账号?
          <router-link to="/login"><span>去登录</span></router-link>
        </p>
      </div>
    </div>
    <div data-v-013e36d2="" class="footer">@2015 版权所有</div>
  </div>
</template>

<script>
import { register } from "../api/public";
export default {
  data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
            callback(new Error('请再次输入密码'))
         // password 是表单上绑定的字段
      } else if (value !== this.param.password) {
         callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
        ip:'',
      param: {
        username: "",
        password: "",
        confirm_pwd: '',
        parent_code: ''
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        confirm_pwd: [{ required: true, validator: validatePass2,trigger: "blur" }],
        parent_code: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
      },
    };
  },
  mounted(){
    //   var cip = returnCitySN["cip"];
    //  console.log(cip);//cip就是本机ip地址
  },
  methods: {
    submitForm() {
      let _this = this;
      this.$refs.login.validate((valid) => {
        if (valid) {
          let params = {
            user_name: this.param.username,
            user_pwd: this.param.password,
            parent_code: this.param.parent_code,
            ip_addr: localStorage.getItem('ip'),
            region: "",
            browser: "",
            device: "",
            operating_system: "",
          };
          register(params).then((res) => {
            console.log(res);
            this.$message.success("注册成功");
           
            _this.$router.push("/login");
            console.log(_this.$router);
          });

          // this.$router.push("/");
        }
      });
    },
    getUserIP(onNewIP) {
        let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
        let pc = new MyPeerConnection({
            iceServers: []
          });
        let noop = () => {
          };
        let localIPs = {};
        let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
        let iterateIP = (ip) => {
          if (!localIPs[ip]) onNewIP(ip);
          localIPs[ip] = true;
        };
        pc.createDataChannel('');
        pc.createOffer().then((sdp) => {
          sdp.sdp.split('\n').forEach(function (line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
          });
          pc.setLocalDescription(sdp, noop, noop);
        }).catch((reason) => {
            console.log(reason)
        });
        pc.onicecandidate = (ice) => {
          if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
          ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
        };
      }
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;

  overflow: hidden;
}
.ms-login .box {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}
.ms-content {
  padding: 30px 30px;
}
.ms-login .tabs {
  padding-top: 10px;
  text-align: center;
}
.ms-login .tabs p {
  cursor: pointer;
  color: #999;
  font-size: 14px;
}
.ms-login .tabs p span {
  color: #c5963b;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

.free-header {
  height: 82px;
}

.free-header .div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

.free-header .div i {
  cursor: pointer;
  line-height: 82px;
}

.free-header .div img {
  vertical-align: middle;
  max-height: 82px;
}

.free-header .ul {
  padding: 30px 0;
}

.free-header .ul li {
  border: 1px dashed #999;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  color: #999;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  /* float:left; */
}

.free-header .ul .active,
.free-header .ul li:hover {
  border-color: #c5963b;
  color: #c5963b;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  line-height: 54px;
  text-align: center;
  color: #333;
  height: 54px;
}
</style>